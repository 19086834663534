import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; // Updated import statement for Firestore

import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBfypLph3NXLmtGR4A8zj1Bky27-vUJ3b4",
  authDomain: "eventapp-e2871.firebaseapp.com",
  projectId: "eventapp-e2871",
  storageBucket: "eventapp-e2871.appspot.com",
  messagingSenderId: "521745072645",
  appId: "1:521745072645:web:b1a1753df7fd838f5ad26c",
  measurementId: "G-2NCZMYFQ9N"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app); // Use getFirestore instead of firestore
const storage = getStorage(app);

export { db, storage };