import React from 'react';

const HadithList = ({ hadiths }) => {
  // Ensure hadiths is always an array to prevent runtime errors
  if (!Array.isArray(hadiths)) {
    return <div>No hadiths found</div>;
  }

  return (
    <div className="space-y-4">
      {hadiths.map((hadith, index) => (
        <div key={index} className="p-4 shadow-lg rounded-lg">
          {/* Adjust the following according to your actual data structure */}
          {/* Display hadith title if available */}
          {hadith.title && <h3 className="text-lg font-semibold mb-2">{hadith.title}</h3>}
          {/* Display hadith text; you may need to adjust this based on the actual property name */}
          <p className="text-gray-600">{hadith.text || "Hadith text not available"}</p>
          {/* Optionally display other hadith details here, e.g., narrator, source, etc. */}
          {hadith.source && <p className="text-sm text-gray-500">Source: {hadith.source}</p>}
          {/* Here we are assuming 'hadith.source' as a property; adjust as per your data */}
        </div>
      ))}
    </div>
  );
};

export default HadithList;
