import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const AyahOfTheDay = () => {
  const [ayahText, setAyahText] = useState('');
  const [translation, setTranslation] = useState('');
  const [surahName, setSurahName] = useState('');
  const [verseNumber, setVerseNumber] = useState('');
  const [surahNumber, setSurahNumber] = useState('');

  useEffect(() => {
    const getDayOfYear = () => {
      const now = new Date();
      const start = new Date(now.getFullYear(), 0, 0);
      const diff = now - start;
      const oneDay = 1000 * 60 * 60 * 24;
      return Math.floor(diff / oneDay);
    };

    const dayOfYear = getDayOfYear();
    const ayahOfTheDay = dayOfYear % 6236 + 1;

    const fetchAyah = async () => {
      try {
        const resTranslation = await fetch(`https://api.alquran.cloud/v1/ayah/${ayahOfTheDay}/en.asad`);
        const dataTranslation = await resTranslation.json();
        if (dataTranslation.data) {
          setAyahText(dataTranslation.data.text);
          setSurahName(dataTranslation.data.surah.englishName);
          setVerseNumber(dataTranslation.data.numberInSurah);
          setSurahNumber(dataTranslation.data.surah.number);

          // Scroll to the desired ayah element
          const element = document.getElementById(`ayah${dataTranslation.data.numberInSurah}`);
          if (element) {
            element.scrollIntoView();
          }
        }

        const resArabic = await fetch(`https://api.alquran.cloud/v1/ayah/${ayahOfTheDay}`);
        const dataArabic = await resArabic.json();
        setTranslation(dataArabic.data.text);
      } catch (error) {
        console.error("Failed to fetch Ayah of the Day:", error);
      }
    };

    fetchAyah();
  }, []);

  return (
    <div className="relative mt-12 mx-auto p-4 sm:p-6 bg-white rounded-xl shadow-lg max-w-4xl" style={{ fontFamily: 'Nunito, sans-serif' }}>
      <div className="absolute -top-8 -right-8 w-24 h-24 sm:w-32 sm:h-32">
        {/* SVG Placeholder */}
      </div>
      <h2 className="text-2xl sm:text-3xl font-bold mb-2 text-black">Ayah of the Day</h2>
      <p className="text-lg sm:text-xl mb-2 font-lateef text-black">{translation}</p>
      <p className="text-sm sm:text-md text-gray-600">{ayahText}</p>
      <Link to={`/surat/${surahNumber}#${verseNumber}`}>
        <button className="btn mt-4 bg-black p-2">Go to Surah {surahName}, Verse {verseNumber}</button>
      </Link>
    </div>
  );
};

export default AyahOfTheDay;
