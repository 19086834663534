const API_BASE_URL = 'https://www.hadithapi.com/api';
const API_KEY = '$2y$10$yMMsvvZWKwlu41Mhvv1MQeQho5V7QnGz7WpGaBaq0asmBqw1lntJK';

/**
 * Fetches hadiths based on the given parameters.
 * @param {Object} params Parameters for the API request.
 * @returns {Promise<Object>} The response from the API.
 */
const fetchHadiths = async (params = {}) => {
  const url = new URL(`${API_BASE_URL}/hadiths`);
  url.searchParams.append('apiKey', API_KEY);

  Object.keys(params).forEach(key => {
    if (params[key]) {
      url.searchParams.append(key, params[key]);
    }
  });

  return performApiRequest(url);
};

/**
 * Fetches chapters from a specific book.
 * @param {string} bookSlug The slug of the book to fetch chapters from.
 * @param {number} [paginate] Optional. Number of chapters to paginate.
 * @returns {Promise<Object>} The response from the API.
 */
const fetchHadithsByBook = async (bookSlug, paginate) => {
  const url = new URL(`${API_BASE_URL}/${bookSlug}/chapters`);
  url.searchParams.append('apiKey', API_KEY);

  if (paginate) {
    url.searchParams.append('paginate', paginate);
  }

  return performApiRequest(url);
};

/**
 * Performs the API request.
 * @param {URL} url The URL object with the full API request URL.
 * @returns {Promise<Object>} The JSON response from the API.
 */
const performApiRequest = async (url) => {
  try {
    const response = await fetch(url.toString());
    if (!response.ok) {
      throw new Error(`API request failed with status ${response.status}: ${response.statusText}`);
    }
    const data = await response.json();
    return data; // The data structure should be handled by the calling component
  } catch (error) {
    console.error("API request failed:", error);
    throw error; // Rethrow to allow error handling in the calling component
  }
};

export { fetchHadiths, fetchHadithsByBook };
