import React, { useState, useEffect } from 'react';
import HadithSearch from './HadithSearch';
import HadithList from './HadithList';
import { fetchHadithsByBook } from './services/hadithService';

const HadithPage = () => {
  const [hadiths, setHadiths] = useState([]);
  const [hadithOfTheDay, setHadithOfTheDay] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    // List the books you're interested in
    const bookSlugs = ['sahih-bukhari', 'sahih-muslim', 'al-tirmidhi'];
    
    // Fetching 2 hadiths from each book for demonstration
    const fetchLimitPerBook = 2;
    
    const fetchInitialHadiths = async () => {
      setLoading(true);
      let initialHadiths = [];
      try {
        for (const bookSlug of bookSlugs) {
          const response = await fetchHadithsByBook(bookSlug, { limit: fetchLimitPerBook });
          if (response && response.hadiths) {
            initialHadiths = [...initialHadiths, ...response.hadiths];
          }
        }
        setHadiths(initialHadiths);
        selectHadithOfTheDay(initialHadiths);
      } catch (error) {
        console.error("Fetching initial Hadiths failed:", error);
        setError('Failed to fetch initial Hadiths. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchInitialHadiths();
  }, []);

  const selectHadithOfTheDay = (hadithsArray) => {
    const dayOfYear = Math.floor((new Date() - new Date(new Date().getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24);
    setHadithOfTheDay(hadithsArray[dayOfYear % hadithsArray.length]);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="container mx-auto p-4">
      <HadithSearch onSearch={(params) => console.log(params)} />
      {hadithOfTheDay && (
        <div>
          <h2 className="text-xl font-semibold mb-4">Hadith of the Day</h2>
          <p>{hadithOfTheDay.text}</p> {/* Adjust this according to your actual data structure */}
        </div>
      )}
      <HadithList hadiths={hadiths} />
    </div>
  );
};

export default HadithPage;
