import React, { useState } from 'react';
import { motion } from 'framer-motion';

const PrayerTimes = () => {
  const [prayerTimes, setPrayerTimes] = useState(null);
  const [location, setLocation] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchPrayerTimes = async () => {
    setIsLoading(true);
    try {
      const url = `https://api.aladhan.com/v1/timingsByCity?city=${location}&country=US&method=2`;
      const response = await fetch(url);
      const data = await response.json();
      const { Fajr, Dhuhr, Asr, Maghrib, Isha } = data.data.timings;
      setPrayerTimes({ Fajr, Dhuhr, Asr, Maghrib, Isha });
    } catch (error) {
      console.error("Failed to fetch prayer times:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const convertToStandardTime = (time) => {
    let [hours, minutes] = time.split(':').map(num => parseInt(num, 10));
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    hours = hours || 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minutes} ${ampm}`;
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-lg mt-6"
    >
      <h2 className="text-3xl font-bold text-center text-gray-800 mb-4">Prayer Times</h2>
      <div className="flex flex-col items-center">
        <input
          type="text"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          placeholder="Enter your city"
          className="input input-bordered w-full max-w-md mb-4"
        />
        <button 
          onClick={fetchPrayerTimes} 
          className={`btn btn-primary ${isLoading ? 'loading' : ''}`}
          disabled={isLoading}
        >
          {isLoading ? 'Loading...' : 'Get Prayer Times'}
        </button>
      </div>
      {prayerTimes && (
        <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-4">
          {Object.entries(prayerTimes).map(([key, value]) => (
            <div key={key} className="flex justify-between items-center p-4 bg-gray-100 rounded-lg">
              <span className="font-semibold text-lg capitalize text-gray-700">{key}</span>
              <span className="font-medium text-gray-600">{convertToStandardTime(value)}</span>
            </div>
          ))}
        </div>
      )}
    </motion.div>
  );
};

export default PrayerTimes;
