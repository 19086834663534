import React, { useState } from 'react';

const HadithSearch = ({ onSearch }) => {
  const [searchParams, setSearchParams] = useState({
    hadithEnglish: '',
    hadithUrdu: '',
    hadithArabic: '',
    hadithNumber: '',
    book: '',
    chapter: '',
    status: '',
    paginate: 25,
  });

  const books = [
    { label: 'Sahih Bukhari', value: 'sahih-bukhari' },
    { label: 'Sahih Muslim', value: 'sahih-muslim' },
    { label: "Jami' Al-Tirmidhi", value: 'al-tirmidhi' },
    { label: 'Sunan Abu Dawood', value: 'abu-dawood' },
    { label: 'Sunan Ibn-e-Majah', value: 'ibn-e-majah' },
    { label: 'Sunan An-Nasa`i', value: 'sunan-nasai' },
    { label: 'Mishkat Al-Masabih', value: 'mishkat' },
    { label: 'Musnad Ahmad', value: 'musnad-ahmad' },
    { label: 'Al-Silsila Sahiha', value: 'al-silsila-sahiha' },
  ];

  const statuses = [
    { label: 'Sahih', value: 'Sahih' },
    { label: 'Hasan', value: 'Hasan' },
    { label: 'Da`eef', value: 'Da`eef' },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchParams((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchParams(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(searchParams);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <input
          name="hadithEnglish"
          type="text"
          placeholder="Search in English..."
          value={searchParams.hadithEnglish}
          onChange={handleInputChange}
          className="input p-2 border rounded"
        />

        {/* Repeat inputs for hadithUrdu and hadithArabic */}

        <select name="book" value={searchParams.book} onChange={handleChange} className="select p-2 border rounded bg-white">
          <option value="">Select Book</option>
          {books.map((book) => (
            <option key={book.value} value={book.value}>
              {book.label}
            </option>
          ))}
        </select>

        <input
          name="chapter"
          type="number"
          placeholder="Chapter number"
          value={searchParams.chapter}
          onChange={handleChange}
          className="input p-2 border rounded"
        />

        <select name="status" value={searchParams.status} onChange={handleChange} className="select p-2 border rounded bg-white">
          <option value="">Select Status</option>
          {statuses.map((status) => (
            <option key={status.value} value={status.value}>
              {status.label}
            </option>
          ))}
        </select>
      </div>

      <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out">
        Search
      </button>
    </form>
  );
};

export default HadithSearch;


