import React from 'react';

const prayersInfo = [
  {
    name: 'Fajr',
    rakats: 2,
    steps: [
      'Niyyah (Intention)',
      'Takbir',
      'Qiyam',
      'Recitation (Al-Fatiha and another Surah)',
      'Ruku',
      'Sujood',
      'Tashahhud and Salam'
    ]
  },
  {
    name: 'Dhuhr',
    rakats: 4,
    steps: [
      // Steps similar to Fajr
    ]
  },
  {
    name: 'Asr',
    rakats: 4,
    steps: [
      // Steps similar to Fajr
    ]
  },
  {
    name: 'Maghrib',
    rakats: 3,
    steps: [
      // Steps similar to Fajr, with slight adjustments for 3 Rakats
    ]
  },
  {
    name: 'Isha',
    rakats: 4,
    steps: [
      // Steps similar to Fajr
    ]
  }
];

const PrayerSteps = ({ prayer }) => (
  <>
    <h2 className="text-xl font-bold">{prayer.name} ({prayer.rakats} Rak'ahs)</h2>
    <ol className="list-decimal pl-6">
      {prayer.steps.map((step, index) => (
        <li key={index} className="mt-1">{step}</li>
      ))}
    </ol>
  </>
);

const Salat = () => (
  <div className="p-8">
    <h1 className="text-2xl font-bold">How to Perform Salat (Prayer)</h1>
    <p className="mt-4">
      Salat is a form of worship and a way to connect with Allah. Here are the steps to perform your prayer, outlined for each of the five daily prayers.
    </p>
    {prayersInfo.map((prayer, index) => (
      <div key={index} className="mt-6">
        <PrayerSteps prayer={prayer} />
      </div>
    ))}
  </div>
);

export default Salat;
