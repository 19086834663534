import React, { useState, useEffect } from 'react';
import { db } from '../firebase-config';
import { collection, getDocs } from 'firebase/firestore';

const Community = () => {
  const [discussions, setDiscussions] = useState([]);
  const [selectedDiscussion, setSelectedDiscussion] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);

  useEffect(() => {
    const fetchDiscussions = async () => {
      const querySnapshot = await getDocs(collection(db, 'discussions'));
      setDiscussions(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    fetchDiscussions();
  }, []);

  const handleDiscussionClick = (discussion) => {
    setSelectedDiscussion(discussion);
  };

  const handleMemberClick = (member) => {
    setSelectedMember(member);
  };

  const handleCloseModal = () => {
    setSelectedDiscussion(null);
    setSelectedMember(null);
  };

  return (
    <div className="container mx-auto py-8">
      <h1 className="text-3xl font-bold mb-4">Muslim Community</h1>
      <p className="mb-8">Connect with fellow Muslims on campus and build lasting friendships.</p>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Discussion Forum</h2>
        <ul>
          {discussions.map((discussion) => (
            <li
              key={discussion.id}
              className="mb-4 p-4 bg-white rounded-lg shadow-md cursor-pointer"
              onClick={() => handleDiscussionClick(discussion)}
            >
              <h3 className="text-lg font-bold">{discussion.title}</h3>
              <p className="text-sm text-gray-500">By {discussion.author}</p>
            </li>
          ))}
        </ul>
      </div>

      <div>
        <h2 className="text-2xl font-bold mb-4">Member Directory</h2>
        <ul>
          {/* Render the list of members */}
          {/* Implement member search and filtering */}
        </ul>
      </div>

      {selectedDiscussion && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 w-full md:w-2/3">
            <h2 className="text-2xl font-bold mb-4">{selectedDiscussion.title}</h2>
            <p className="text-sm text-gray-500 mb-4">By {selectedDiscussion.author}</p>
            <p className="mb-4">{selectedDiscussion.content}</p>
            <button
              className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md mt-4"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {selectedMember && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 w-full md:w-1/2">
            <h2 className="text-2xl font-bold mb-4">{selectedMember.name}</h2>
            <p className="mb-4">{selectedMember.bio}</p>
            <button
              className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md mt-4"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Community;