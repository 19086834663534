import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Wudu from './pages/Wudu';
import Salat from './pages/Salat';
import Pillars from './pages/Pillars';
import LoginPage from './pages/LoginPage';
import AdminPage from './pages/Admin';
import FAQ from './pages/Faq';
import HadithPage from './pages/HadithPage';
import Quran from "./Quran";
import SingleSurat from "./SingleSurat";
import Community from './pages/Community';
import Events from './pages/Events';
import Learn from './pages/Learn';
import Footer from './components/Footer'; // Import the Footer component

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setIsAuthenticated(!!user);
    });
    return () => unsubscribe(); // Cleanup subscription
  }, []);

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/wudu" element={<Wudu />} />
            <Route path="/salat" element={<Salat />} />
            <Route path="/pillars" element={<Pillars />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/surat" element={<Quran />} />
            <Route path="/surat/:idsurat" element={<SingleSurat />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/admin" element={isAuthenticated ? <AdminPage /> : <Navigate to="/login" />} />
            <Route path="/hadith" element={<HadithPage />} />
            <Route path="/community" element={<Community />} />
            <Route path="/events" element={<Events />} />
            <Route path="/learn" element={<Learn />} />
          </Routes>
        </div>
        <Footer /> {/* Add the Footer component */}
      </div>
    </Router>
  );
}

export default App;