import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../images/logo.jpg'; // Ensure this is the correct path to your logo
import HamburgerIcon from '../images/burger.svg'; // Importing the hamburger menu SVG

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="flex justify-between items-center h-16 bg-white text-gray-800 shadow-md font-mono relative" role="navigation">
      <div className="flex items-center pl-8">
        <NavLink to="/" onClick={() => setIsMenuOpen(false)}>
          <img src={logo} alt="Logo" className="h-10 mr-2" />
        </NavLink>
        <NavLink to="/" className="text-gray-800 text-lg font-semibold" onClick={() => setIsMenuOpen(false)}>Suhbaah</NavLink>
      </div>
      
      <button
        className="px-4 py-2 outline-none focus:outline-none md:hidden" 
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        aria-controls="navbar-menu"
        aria-expanded={isMenuOpen}
        aria-label="Toggle navigation"
      >
        <img src={HamburgerIcon} alt="Menu" className="h-6 w-6" />
      </button>
      <div className={`${isMenuOpen ? "flex" : "hidden"} flex-col md:flex md:flex-row pr-8 md:items-center absolute md:relative top-full left-0 w-full md:w-auto bg-white md:bg-transparent shadow-md md:shadow-none z-10`} id="navbar-menu">
        <NavLink to="/surat" className="p-4 hover:bg-gray-800 bg-black text-white" onClick={() => setIsMenuOpen(false)}>Quran</NavLink>
       <NavLink to="/faq" className="p-4 hover:bg-gray-800 bg-black text-white" onClick={() => setIsMenuOpen(false)}>FAQ</NavLink>
      </div>
    </nav>
  );
};

export default Navbar;
