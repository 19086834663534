import React, { useState, useEffect } from 'react';
import { db } from '../firebase-config';
import { collection, getDocs } from 'firebase/firestore';

const Events = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isRegistering, setIsRegistering] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');

  useEffect(() => {
    const fetchEvents = async () => {
      const querySnapshot = await getDocs(collection(db, 'events'));
      setEvents(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    fetchEvents();
  }, []);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
  };

  const handleCloseModal = () => {
    setSelectedEvent(null);
    setIsRegistering(false);
  };

  const filteredEvents = events.filter((event) => {
    const matchesSearch = event.name.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCategory = selectedCategory === '' || event.category === selectedCategory;
    const matchesLocation = selectedLocation === '' || event.location === selectedLocation;
    return matchesSearch && matchesCategory && matchesLocation;
  });

  return (
    <div className="container mx-auto py-8">
      <h1 className="text-3xl font-bold mb-4">Upcoming Events</h1>
      <p className="mb-8">Discover and participate in Islamic events and activities on campus.</p>

      <div className="mb-8">
        <input
          type="text"
          placeholder="Search events..."
          className="border border-gray-300 rounded-md px-4 py-2 w-full md:w-1/2"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div className="mt-4">
          <select
            className="border border-gray-300 rounded-md px-4 py-2 mr-4"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="">All Categories</option>
            <option value="lectures">Lectures</option>
            <option value="workshops">Workshops</option>
            <option value="socials">Socials</option>
          </select>
          <select
            className="border border-gray-300 rounded-md px-4 py-2"
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
          >
            <option value="">All Locations</option>
            <option value="campus">On Campus</option>
            <option value="online">Online</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {filteredEvents.map((event) => (
          <div
            key={event.id}
            className="bg-white rounded-lg shadow-md p-6 cursor-pointer"
            onClick={() => handleEventClick(event)}
          >
            <h2 className="text-xl font-bold mb-2">{event.name}</h2>
            <p className="text-gray-600">{event.description}</p>
            <p className="text-sm text-gray-500 mt-2">
              Date: {event.date} | Time: {event.startTime} - {event.endTime}
            </p>
            <p className="text-sm text-gray-500">Location: {event.location}</p>
          </div>
        ))}
      </div>

      {selectedEvent && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 w-full md:w-1/2">
            <h2 className="text-2xl font-bold mb-4">{selectedEvent.name}</h2>
            <p className="mb-4">{selectedEvent.description}</p>
            <p className="mb-4">
              <strong>Date:</strong> {selectedEvent.date}
            </p>
            <p className="mb-4">
              <strong>Time:</strong> {selectedEvent.startTime} - {selectedEvent.endTime}
            </p>
            <p className="mb-4">
              <strong>Location:</strong> {selectedEvent.location}
            </p>
            {!isRegistering ? (
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4"
                onClick={() => setIsRegistering(true)}
              >
                Register
              </button>
            ) : (
              <div>
                <h3 className="text-xl font-bold mb-4">Registration Form</h3>
                <form>
                  <div className="mb-4">
                    <label htmlFor="name" className="block mb-2">
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="border border-gray-300 rounded-md px-4 py-2 w-full"
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="email" className="block mb-2">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="border border-gray-300 rounded-md px-4 py-2 w-full"
                    />
                  </div>
                  <button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 rounded-md"
                  >
                    Submit
                  </button>
                </form>
              </div>
            )}
            <button
              className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md mt-4 ml-4"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Events;