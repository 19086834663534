import React, { useEffect, useState } from 'react';
import { db } from '../firebase-config';
import { collection, getDocs } from 'firebase/firestore';

const FAQ = () => {
  const [faqs, setFaqs] = useState([]);
  const faqsCollectionRef = collection(db, "faqs");

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const querySnapshot = await getDocs(faqsCollectionRef);
        setFaqs(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error('Error fetching FAQs:', error);
        // Handle error gracefully, e.g., display a message to the user
      }
    };
  
    fetchFaqs();
  }, [faqsCollectionRef]);
  
  return (
    <div className="p-5">
      <h1 className="text-3xl font-bold mb-8 text-center">Frequently Asked Questions</h1>
      <div className="max-w-4xl mx-auto">
        {faqs.map((faq) => (
          <div key={faq.id} className="mb-8 p-6 bg-white shadow-lg rounded-lg">
            <h2 className="font-semibold text-xl mb-2">{faq.question}</h2>
            <p className="mb-4">{faq.answer}</p>
            {faq.sources && faq.sources.length > 0 && (
              <div className="mb-4">
                <h3 className="font-semibold">Sources:</h3>
                <ul>
                  {faq.sources.map((source, index) => (
                    <li key={index}>
                      <a href={source} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-600 transition duration-300 ease-in-out">
                        {source}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {faq.images && faq.images.length > 0 && (
              <div className="flex flex-wrap -m-1">
                {faq.images.map((image, index) => (
                  <a key={index} href={image} target="_blank" rel="noopener noreferrer" className="p-1">
                    <img src={image} alt={`FAQ Image ${index + 1}`} className="object-cover w-full h-full rounded-lg" style={{ maxWidth: '200px', maxHeight: '200px' }}/>
                  </a>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQ;
