import React from 'react';
import { Transition } from '@headlessui/react';

const Pillars = () => {
  const pillars = [
    {
      title: 'Shahada (Faith)',
      description: 'The declaration of faith, stating that there is no god but Allah, and Muhammad is the Messenger of Allah.',
    },
    {
      title: 'Salat (Prayer)',
      description: 'Performing the five daily prayers that are prescribed to Muslims as a direct link to Allah.',
    },
    {
      title: 'Zakat (Charity)',
      description: 'Giving to those in need and supporting the community through mandatory giving.',
    },
    {
      title: 'Sawm (Fasting)',
      description: 'Fasting during the month of Ramadan, which includes refraining from food, drink, and other physical needs during daylight hours.',
    },
    {
      title: 'Hajj (Pilgrimage to Mecca)',
      description: 'The pilgrimage to Mecca that every Muslim must undertake at least once in their lifetime if they are able to do so.',
    },
  ];

  return (
    <div className="p-8 bg-gray-100 rounded-lg shadow-md">
      <h1 className="text-4xl font-bold text-center mb-8 text-gray-800">The Five Pillars of Islam</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {pillars.map((pillar, index) => (
          <Transition
            show={true}
            key={index}
            enter="transition-transform duration-500 ease-in-out transform"
            enterFrom="opacity-0 scale-50"
            enterTo="opacity-100 scale-100"
            leave="transition-transform duration-300 ease-in-out transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-90"
          >
            <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow">
              <h2 className="text-2xl font-bold mb-4 text-gray-800">{pillar.title}</h2>
              <p className="text-gray-700 mb-4">{pillar.description}</p>
            </div>
          </Transition>
        ))}
      </div>
      <p className="mt-8 text-center text-lg text-gray-700">
        These pillars are a guide for Muslims to live a balanced, righteous life, staying on a path that brings them closer to Allah.
      </p>
    </div>
  );
};

export default Pillars;
