import React from 'react';
import { useNavigate } from 'react-router-dom';

const Navigation = ({ suratP, artiP, loadP }) => {
  const navigate = useNavigate();

  return (
    <nav className="bg-white/90 backdrop-blur-lg border-b-2 sticky top-0 z-[100] shadow-md">
      <div className="container mx-auto px-4 py-3 flex items-center justify-between"> {/* Adjusted padding for better alignment */}
        <button
          onClick={() => navigate(-1)}
          className="inline-flex items-center gap-2 text-sm font-medium text-gray-700 bg-gradient-to-r from-sky-400 to-blue-800 hover:bg-gradient-to-l hover:from-sky-300 hover:to-blue-700 px-4 py-2 rounded-md shadow focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2 transition-colors duration-150"
          aria-label="Go back"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2} className="w-5 h-5 text-white">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
          </svg>
          <span className="text-white">Back</span>
        </button>

        {loadP ? (
          <div className="flex flex-col items-end"> {/* Adjusted for right alignment */}
            <h1 className="text-lg font-semibold text-gray-800">{suratP}</h1> {/* Increased size for emphasis */}
            <p className="text-sm text-slate-500">{artiP}</p>
          </div>
        ) : (
          <div className="animate-pulse flex flex-col items-end">
            <div className="h-6 bg-slate-300 rounded w-36 mb-2"></div> {/* Adjusted for a better loading state representation */}
            <div className="h-4 bg-slate-200 rounded w-28"></div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navigation;
