import React, { useEffect, useState } from 'react';
import { db } from '../firebase-config';
import { collection, onSnapshot } from 'firebase/firestore';

const EventsContainer = () => {
    const [eventsByMonth, setEventsByMonth] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Group events by their month and year
    const groupEventsByMonth = (events) => {
        const grouped = {};
        events.forEach(event => {
            const monthYear = new Date(event.date).toLocaleString(undefined, { month: 'long', year: 'numeric' });
            if (!grouped[monthYear]) {
                grouped[monthYear] = [];
            }
            grouped[monthYear].push(event);
        });
        return grouped;
    };

    // Format the date string
    const formatDate = (dateStr) => {
        const date = new Date(dateStr + 'T00:00Z');
        return date.toLocaleDateString(undefined, { day: 'numeric', month: 'long', year: 'numeric' });
    };

    // Format time from a string
    const formatTime = (timeStr) => {
        if (!timeStr) return '';
        const [hours, minutes] = timeStr.split(':');
        const date = new Date();
        date.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0);
        return date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', hour12: true });
    };

    // Function to copy event link to clipboard
    const handleShare = (eventId) => {
        const url = `${window.location.href}#${eventId}`;
        navigator.clipboard.writeText(url).then(() => {
            alert("Event link copied to clipboard!");
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    };

    // Fetch and listen for events from Firestore in real-time
    useEffect(() => {
        const eventsRef = collection(db, 'events');

        const unsubscribe = onSnapshot(eventsRef, snapshot => {
            const events = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setEventsByMonth(groupEventsByMonth(events));
            setLoading(false);
        }, err => {
            setError(err);
            setLoading(false);
        });

        // Cleanup function to unsubscribe from the listener on component unmount
        return () => unsubscribe();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    const handleRSVP = (eventId) => {
        // Implement your RSVP logic here
        alert(`RSVP for event ${eventId}`);
    };

    return (
        <div>
            {Object.entries(eventsByMonth).map(([monthYear, events]) => (
                <div key={monthYear}>
                    <h2 className="text-xl font-bold my-4 pl-4">{monthYear}</h2> {/* Added pl-4 for indentation */}
                    {events.map(event => (
  <div key={event.id} id={event.id} className="bg-white p-4 rounded shadow mb-4">
    <h3 className="text-lg font-semibold">{event.name}</h3>
    {event.imageUrl && <img src={event.imageUrl} alt={event.name} className="w-1/3 h-auto mb-2" />}
    <p className="text-gray-600 mb-1">{event.description}</p>
    <p className="text-gray-600">Date: {formatDate(event.date)}</p>
    <p className="text-gray-600 mb-1">Time: {formatTime(event.startTime)} to {formatTime(event.endTime)}</p>
    <p className="text-gray-600">Location: {event.location}</p>
    
    {/* RSVP Button */}
    {event.rsvpLink && (
      <a href={event.rsvpLink} target="_blank" rel="noopener noreferrer" className="inline-block bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded">
        RSVP
      </a>
    )}

    <button onClick={() => handleShare(event.id)} className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded">
      Share
    </button>
  </div>
))}
                </div>
            ))}
            {Object.keys(eventsByMonth).length === 0 && (
                <div>No events to display.</div>
            )}
        </div>
    );
};

export default EventsContainer;
