import React, { useState, useEffect } from 'react';
import { db } from '../firebase-config';
import { collection, getDocs } from 'firebase/firestore';

const Learn = () => {
  const [learningMaterials, setLearningMaterials] = useState([]);
  const [selectedResource, setSelectedResource] = useState(null);

  useEffect(() => {
    const fetchLearningMaterials = async () => {
      const querySnapshot = await getDocs(collection(db, 'learningMaterials'));
      setLearningMaterials(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    fetchLearningMaterials();
  }, []);

  const handleResourceClick = (resource) => {
    setSelectedResource(resource);
  };

  const handleCloseModal = () => {
    setSelectedResource(null);
  };

  return (
    <div className="container mx-auto py-8">
      <h1 className="text-3xl font-bold mb-4">Learn About Islam</h1>
      <p className="mb-8">Discover the beauty and teachings of Islam.</p>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {learningMaterials.map((material) => (
          <div
            key={material.id}
            className="cursor-pointer bg-white rounded-lg shadow-md p-6"
            onClick={() => handleResourceClick(material)}
          >
            <h2 className="text-xl font-bold mb-2">{material.title}</h2>
            <p className="text-gray-600">{material.description}</p>
            <p className="text-sm text-gray-500 mt-2">Category: {material.category}</p>
          </div>
        ))}
      </div>

      {selectedResource && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 w-full md:w-2/3">
            <h2 className="text-2xl font-bold mb-4">{selectedResource.title}</h2>
            <p className="mb-4">{selectedResource.description}</p>
            <div
              className="prose"
              dangerouslySetInnerHTML={{ __html: selectedResource.content }}
            />
            <button
              className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md mt-4"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Learn;