const Wudu = () => (
    <div className="wudu-page p-8">
      <h1 className="text-2xl font-bold">How to Perform Wudu (Ablution)</h1>
      <p className="mt-4">
        Wudu is a purification process for prayer, preparing physically and mentally for worship. Here are the detailed steps:
      </p>
      <ol className="list-decimal list-inside mt-2">
        <li><strong>Intention (Niyyah):</strong> Begin with the intention to perform Wudu for the sake of Allah.</li>
        <li><strong>Wash hands:</strong> Wash your hands three times, ensuring water covers every part from the fingertips to the wrists.</li>
        <li><strong>Rinse mouth and nose:</strong> Rinse your mouth three times, swirling the water around in your mouth, and inhale water into your nose to clean it, blowing it out three times.</li>
        <li><strong>Wash face:</strong> Wash your entire face three times from the right ear to the left ear and from the forehead to the bottom of the chin, making sure the whole face is washed.</li>
        <li><strong>Wash arms:</strong> Wash the right arm, including the elbow, three times, and then do the same with the left arm.</li>
        <li><strong>Wiping the head:</strong> Move wet hands over your head from the forehead to the back of the head and back to the forehead once.</li>
        <li><strong>Wash ears:</strong> Use your index fingers to wash the inside of your ears and your thumbs for the outside, once.</li>
        <li><strong>Wash feet:</strong> Wash your right foot up to and including the ankle three times, making sure water covers all parts, then wash your left foot in the same manner.</li>
      </ol>
      <p className="mt-4">
        After completing these steps, your Wudu is complete, and you're ready to pray. Wudu is not only a physical purification but also a mental preparation to stand before Allah.
      </p>
    </div>
  );
  
  export default Wudu;
  