import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import Navigation from './Navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import CopyToClipboard from 'react-copy-to-clipboard';

const AudioPlayer = ({ src }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    };
  }, []);

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  };

  return (
    <div className="flex items-center space-x-2">
      <button onClick={togglePlayPause} className="btn btn-icon btn-sm">
        {isPlaying ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />}
      </button>
      <audio ref={audioRef} src={src} hidden onEnded={() => setIsPlaying(false)}></audio>
    </div>
  );
};

const SingleSurat = () => {
  const { idsurat } = useParams();
  const [loading, setLoading] = useState(true);
  const [surahDetails, setSurahDetails] = useState({});
  const [ayahs, setAyahs] = useState([]);
  const [fontArabicSize, setFontArabicSize] = useState('24px');
  const [fontTranslationSize, setFontTranslationSize] = useState('18px');

  useEffect(() => {
    const fetchSurahDetailsAndTranslations = async () => {
      setLoading(true);
      try {
        const resArabic = await fetch(`https://api.alquran.cloud/v1/surah/${idsurat}/ar.alafasy`);
        const dataArabic = await resArabic.json();
        const resTranslation = await fetch(`https://api.alquran.cloud/v1/surah/${idsurat}/en.sahih`);
        const dataTranslation = await resTranslation.json();

        if (dataArabic.data && dataTranslation.data) {
          setSurahDetails({
            name: dataArabic.data.englishName,
            number: dataArabic.data.number,
            numberOfAyahs: dataArabic.data.numberOfAyahs,
            revelationType: dataArabic.data.revelationType,
            prevSurah: dataArabic.data.prevSurah,
            nextSurah: dataArabic.data.nextSurah,
          });
          const combinedAyahs = dataArabic.data.ayahs.map((ayah, index) => ({
            ...ayah,
            translation: dataTranslation.data.ayahs[index].text,
          }));
          setAyahs(combinedAyahs);
        }
      } catch (error) {
        console.error("Failed to fetch Surah details and translations:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSurahDetailsAndTranslations();
  }, [idsurat]);

  return (
    <>
      <Navigation suratP={surahDetails.name} artiP={surahDetails.revelationType} loadP={!loading} />
      <div className="container mx-auto my-5 px-4">
        <div className="flex justify-between items-center mb-4">
          <div>
            <label htmlFor="fontArabicSize" className="mr-2">Arabic Font Size:</label>
            <select id="fontArabicSize" value={fontArabicSize} onChange={e => setFontArabicSize(e.target.value)} className="select select-bordered select-sm">
              <option value="18px">Small</option>
              <option value="24px">Medium</option>
              <option value="30px">Large</option>
            </select>
          </div>
          <div>
            <label htmlFor="fontTranslationSize" className="mr-2">Translation Font Size:</label>
            <select id="fontTranslationSize" value={fontTranslationSize} onChange={e => setFontTranslationSize(e.target.value)} className="select select-bordered select-sm">
              <option value="14px">Small</option>
              <option value="18px">Medium</option>
              <option value="22px">Large</option>
            </select>
          </div>
        </div>
        {loading ? (
          <div className="text-center">Loading...</div>
        ) : (
          <>
            <h1 className="text-3xl font-bold text-center mb-4">{surahDetails.name}</h1>
            {ayahs.map((ayah, index) => (
              <div key={index} className="mb-6">
                <CopyToClipboard text={ayah.text}>
                  <div className="text-right mb-2 cursor-pointer" style={{ fontSize: fontArabicSize, fontFamily: "'Lateef', cursive" }}>
                    {ayah.text} <span className="text-sm font-normal">({ayah.numberInSurah})</span>
                  </div>
                </CopyToClipboard>
                <AudioPlayer src={ayah.audio} />
                <p style={{ fontSize: fontTranslationSize }}>
                  {ayah.numberInSurah}. {ayah.translation}
                </p>
              </div>
            ))}
            <div className="flex justify-between">
              {surahDetails.prevSurah && <Link to={`/surat/${surahDetails.prevSurah.number}`} className="btn btn-primary">Previous Surah</Link>}
              {surahDetails.nextSurah && <Link to={`/surat/${surahDetails.nextSurah.number}`} className="btn btn-primary">Next Surah</Link>}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SingleSurat;
