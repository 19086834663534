import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PrayerTimes from '../components/PrayerTimes';
import AyahOfTheDay from '../components/Aya';
import EventList from './EventList';
import EventCalendar from './EventCalendar';
import { db } from '../firebase-config';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import logo from '../images/logo.jpg';

const Home = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [viewMode, setViewMode] = useState('list');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    const unsubscribe = loadEvents();
    return () => unsubscribe();
  }, []);

  const loadEvents = () => {
    const eventsQuery = query(collection(db, 'events'), where('status', '==', 'approved'));
    return onSnapshot(eventsQuery, (snapshot) => {
      const eventsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        start: new Date(doc.data().date),
        end: new Date(doc.data().date),
      }));
      setEvents(eventsData);
    }, error => {
      console.error('Error fetching events:', error);
    });
  };

  const navigateTo = path => () => navigate(path);

  const openModal = (event) => {
    setSelectedEvent(event);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedEvent(null);
    setIsModalOpen(false);
  };

  return (
    <div className="font-sans">
      <div className="text-white bg-blue-900 text-center p-12">
        <h1 className="text-5xl font-bold mb-4">Spread the Light of Islam</h1>
        <p className="max-w-xl mx-auto mb-8 text-lg">Join our community and make a difference on campus.</p>
        <AyahOfTheDay />
      </div>
      <div className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto grid gap-8 grid-cols-1 md:grid-cols-3">
          <FeatureCard
            title="Connect with Muslims"
            description="Find companionship and support within our community."
            onClick={navigateTo('/community')}
            image={logo}
          />
          <FeatureCard
            title="Upcoming Events"
            description="Participate in Islamic events and activities on campus."
            onClick={navigateTo('/events')}
            image={logo}
          />
          <FeatureCard
            title="Learn About Islam"
            description="Discover the beauty and teachings of Islam."
            onClick={navigateTo('/learn')}
            image={logo}
          />
        </div>
      </div>
      <PrayerTimes className="py-12" />
      <div className="flex justify-center gap-4 my-8">
        <button
          onClick={() => setViewMode('list')}
          className={`px-4 py-2 rounded-md ${viewMode === 'list' ? 'bg-green-500 text-white' : 'bg-gray-200'}`}
        >
          List View
        </button>
        <button
          onClick={() => setViewMode('calendar')}
          className={`px-4 py-2 rounded-md ${viewMode === 'calendar' ? 'bg-green-500 text-white' : 'bg-gray-200'}`}
        >
          Calendar View
        </button>
      </div>
      {viewMode === 'list' && <EventList events={events} onEventClick={openModal} />}
      {viewMode === 'calendar' && <EventCalendar events={events} onEventClick={openModal} />}
      {isModalOpen && selectedEvent && (
        <EventModal event={selectedEvent} onClose={closeModal} />
      )}
    </div>
  );
};

const FeatureCard = ({ title, description, onClick, image }) => (
  <div
    className="cursor-pointer rounded overflow-hidden shadow-lg hover:shadow-2xl transition duration-300 ease-in-out transform hover:-translate-y-1"
    onClick={onClick}
  >
    <img src={image} alt={title} className="w-full h-48 object-cover" />
    <div className="px-6 py-4">
      <h3 className="font-bold text-xl mb-2">{title}</h3>
      <p className="text-gray-700 text-base">{description}</p>
    </div>
  </div>
);

const EventModal = ({ event, onClose }) => (
  <div className="fixed inset-0 flex items-center justify-center z-50">
    <div className="bg-white rounded-lg shadow-lg p-8 max-w-md mx-auto">
      <h2 className="text-2xl font-bold mb-4">{event.title}</h2>
      <p className="text-gray-700 mb-4">{event.description}</p>
      <p className="text-gray-500 mb-4">Date: {event.start.toDateString()}</p>
      <button
        onClick={onClose}
        className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md"
      >
        Close
      </button>
    </div>
    <div
      className="fixed inset-0 bg-black opacity-50"
      onClick={onClose}
    ></div>
  </div>
);

export default Home;