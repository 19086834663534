import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { db } from '../firebase-config';
import { collection, onSnapshot } from 'firebase/firestore'; // Import collection and onSnapshot
import './EventCalendar.css'; // Add this for custom styles
import 'moment-timezone'; // Import moment-timezone

const localizer = momentLocalizer(moment);

moment.tz.setDefault("UTC");

const CustomToolbar = (toolbar) => {
  const goToBack = () => { toolbar.onNavigate('PREV'); };
  const goToNext = () => { toolbar.onNavigate('NEXT'); };
  const goToCurrent = () => { toolbar.onNavigate('TODAY'); };
  const label = toolbar.label;

  return (
    <div className="toolbar-container">
      <button className="button" onClick={goToBack}>&#8249;</button>
      <button className="button" onClick={goToCurrent}>Today</button>
      <button className="button" onClick={goToNext}>&#8250;</button>
      <span className="toolbar-label">{label}</span>
    </div>
  );
};

const EventModal = ({ isOpen, onClose, event }) => {
  if (!isOpen || !event) return null;

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href + `#${event.id}`);
    alert("Event link copied to clipboard!");
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-10">
      <div className="bg-white p-4 rounded shadow-lg max-w-2xl">
        <h3 className="text-lg font-semibold mb-2">{event.title}</h3>
        {event.image && <img src={event.image} alt={event.title} className="w-full max-h-60 object-cover mb-2" />}
        <p>{event.description}</p>
        <p>Date: {new Date(event.start).toLocaleDateString()}</p>
        <p>Time: {moment(event.start).format('LT')} to {moment(event.end).format('LT')}</p>
        <p>Location: {event.location}</p>
        <div className="mt-4">
          <button onClick={handleShare} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2">Share</button>
          <button onClick={onClose} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Close</button>
        </div>
      </div>
    </div>
  );
};

const EventCalendar = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const eventsRef = collection(db, 'events'); // Reference to 'events' collection
    const unsubscribe = onSnapshot(eventsRef, snapshot => { // Listen for changes in 'events' collection
      const fetchedEvents = snapshot.docs.map(doc => ({
        id: doc.id,
        title: doc.data().title,
        start: new Date(doc.data().date + 'T' + doc.data().startTime), // Combine date and start time
        end: new Date(doc.data().date + 'T' + doc.data().endTime), // Combine date and end time
        description: doc.data().description,
        location: doc.data().location,
        image: doc.data().image
      }));
      setEvents(fetchedEvents);
    });
    return () => unsubscribe();
  }, []);

  const eventStyleGetter = (event, start, end, isSelected) => {
    return {
      style: {
        backgroundColor: '#001427',
        borderRadius: '0px',
        opacity: 0.8,
        color: 'white',
        border: '0px',
        display: 'block',
        padding: '2px 5px'
      }
    };
  };

  const onEventClick = (event) => {
    setSelectedEvent(event);
    setIsModalOpen(true);
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Event Calendar</h2>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: '600px' }}
        eventPropGetter={eventStyleGetter}
        onSelectEvent={onEventClick}
        components={{ toolbar: CustomToolbar }}
      />
      <EventModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} event={selectedEvent} />
    </div>
  );
};

export default EventCalendar;
