import React from 'react';
import { FaInstagram, FaEnvelope } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-blue-950 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center">
          <div className="mb-4">
            <h3 className="text-2xl font-bold text-center">Suhbaah</h3>
            <p className="mt-2 text-center">Spreading the light of Islam on campus</p>
          </div>
          <div className="flex justify-center space-x-6 mb-4">
            <a
              href="https://www.instagram.com/suhbah.1/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-gray-300 transition duration-300"
            >
              <FaInstagram size={24} />
            </a>
            <a
              href="mailto:your_email@example.com"
              className="text-white hover:text-gray-300 transition duration-300"
            >
              <FaEnvelope size={24} />
            </a>
          </div>
          <p className="text-center text-sm">&copy; {new Date().getFullYear()} Subhaah. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;